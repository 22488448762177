export default () => ({

    appUrl: "http://localhost:3000/",
    apiUrl: "http://3.210.156.236/api/v1/",

    appUrl: "http://localhost:3000/",

    apiUrl: "https://app.jewacc.com/api/v1",
    currency: "INR"

});