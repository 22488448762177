import React from "react";
import "./css/index.scoped.css";
import {Button } from "reactstrap";
import commands from "../../commands";
import _ from "lodash";

function ThreePromo2() {
  var mybanner2 = commands.cache.getPositionedWebBanners({position: 3})
  var sortedBanner = _.sortBy(mybanner2[0]?.images,["Position"]);
  return (
    <section className="my-3">
      <div className="shopnow yt-three-promo-main">
        <div className="yt-cm-three-main-row d-flex flex-wrap">
          <div className="yt-cm-three-col">
            <div className="yty-bg-3banner hover-me" style={{backgroundImage:`url(${sortedBanner[0]?.image})`}}>
              <div className="yt-3banner-wrapper yt-full-width">
                <h2 className="yt-3banner-title ">
                {sortedBanner[0]?.title}
                </h2>
                <h4 className="yt-3banner-sub-title mt-4 text-secondary">
                {sortedBanner[0]?.subtitle}
                </h4>

                <Button
                  color=" yt-3banner-sp-btn py-3 mt-3"
                  onClick={() => window.location.href=sortedBanner[0]?.url}
                >
                  Shop Now
                </Button>
              </div>
            </div>
            
          </div>
          <div
            className="yt-cm-three-col yt-cm-bg-col hover-me"
            style={{backgroundImage:`url(${sortedBanner[1]?.image})`}}
          >
            <div className="yt-3banner-last-wrap">
              <div className="yt-3banner-big-title">
              {sortedBanner[1]?.title}
              </div>
              <div className="yt-3banner-big-sub-title text-secondary mt-5">
              {sortedBanner[1]?.subtitle}
              </div>
              <Button
                color=" yt-3banner-sp-btn py-3 mt-3"
                onClick={() => window.location.href=sortedBanner[0]?.url}
              >
                Shop Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThreePromo2;
