import uploadPrescription from "./upload-prescription";
import getRazorPayTransaction from "./getRazorPayTransaction";
import verifySignature from './verifyRazorPaySignature';
import cancelPrescription from './cancel-prescription';

export default {
  uploadPrescription,
  getRazorPayTransaction,
  verifySignature,
  cancelPrescription
};
