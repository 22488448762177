import React, { useState } from "react";
import "./css/index.scoped.css";
import { Col, Container, Row, Button } from "reactstrap";
import { useHistory } from "react-router";
import commands from "../../commands";
import parse from "html-react-parser";

function ThreePromo() {
  const history = useHistory();
  var mybanner3 = commands.cache.getPositionedWebBanners({ position: 3 });
  var sortedBanner = _.sortBy(mybanner3[0]?.images, ["Position"]);
  return (
    <section className="yt-theme-promo-component mt-5 mb-5">
      <Container>
        <Row className="yt-cm-thr-pro-row">
          <Col xs={12} sm={3} lg={3} className="yt-cm-col hover-me">
            <img
              // src={require("./images/raamin-ka-IvspK72hb2k-unsplash.png")}
              src={sortedBanner[0]?.image}
              className="promo-3-img img-fluid"
              alt="homeo"
            />
            <div className="promo-3-botom-info d-flex align-items-center justify-content-between px-4">
              <div className="promot-3-title">{sortedBanner[0]?.title}</div>
              <Button
                color=" promot-3-btn "
                onClick={() => (window.location.href = sortedBanner[0]?.url)}
              >
                Shop Now
              </Button>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={6} className="promo-3-col yt-cm-col hover-me">
            <img
              src={sortedBanner[1]?.image}
              className="promo-3-img img-fluid"
              alt="homeo"
            />
            <div className="promo-3-botom-info d-flex align-items-center justify-content-between px-4">
              <div className="promot-3-title">{sortedBanner[1]?.title}</div>
              <Button
                color=" promot-3-btn "
                onClick={() => (window.location.href = sortedBanner[1]?.url)}
              >
                Shop Now
              </Button>
            </div>
          </Col>
          <Col xs={12} sm={3} lg={3} className="yt-cm-col hover-me">
            <img
              // src={require("./images/dharma-saputra-nj-E1pQ5A24-unsplash.png")}
              src={sortedBanner[2]?.image}
              className="promo-3-img img-fluid"
              alt="homeo"
            />
            <div className="promo-3-botom-info d-flex align-items-center justify-content-between px-4">
              <div className="promot-3-title">{sortedBanner[2]?.title}</div>
              <Button
                color=" promot-3-btn "
                onClick={() => (window.location.href = sortedBanner[2]?.url)}
              >
                Shop Now
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

// function ThreePromo() {
//   var banners = commands.cache.getPositionedBanners({ position: 3 });

//   return banners.map((item) => parse(item.content + "<br />"));
// }
export default ThreePromo;
