import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  AppStoreBanner,
  ProductCard,
  PrescriptionPageComponent,
  SingleImageBanner,
} from "../../components";
import "./css/index.scoped.css";
import CacheState from "../../redux/states/cache";
function PrescriptonPage(props) {
  const cacheState = CacheState.get();

  return (
    <div>
      <Header onProps={props} />
      {/*<HeroBanner />*/}
      <PrescriptionPageComponent />

     <SingleImageBanner/>

      {/*Similar Products Product */}
      <ProductCard
        products={cacheState.homepage?.latest_products}
        name="New Products"
        onViewMore={() =>
          props.history.push("/shop?order_field=recommended&page=1&per_page=15")
        }
      />
      {/*Similar Products Product */}

      {/* App Store Banner */}
      <AppStoreBanner />
      {/* App Store Banner End*/}
      <Footer />
    </div>
  );
}
export default PrescriptonPage;
