import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FaTrashAlt, FaPlus, FaMinus } from "react-icons/fa";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
function PageLoadingBlock(props) {
  const history = useHistory();
  const routeToshop = () => {
    let path = "/products";
    history.push(path);
  };
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;
  return (
    <>
      <section>
        <Container>
          <div className="trans-fl-pg-inner-wrap p-4  radius-10 trans-fl-pg-mb-80 mt-5">
            <div className="cart-pg-empty-main-wrap text-center py-5">
            <div className="sweet-loading">
               <RiseLoader
               css={override}
               size={35}
               margin={30}
               color={"#29af9d"}
               loading={true}
                />
                </div>
              <div className="trans-fl-wrap ">
                <h2 className="trans-fl-ttl my-3" style={{ color: "#43b7a7" }}>
                  {props.title ? props.title : "Oh Noes!, Error Occured"}
                </h2>
                <p className="trans-fl-text mb-0">{props.message ? props.message : "Your order was declined!"}</p>
              </div>
              {props.onButtonPress &&
                <Button color=" trans-fl-btn py-3 px-5 mt-3" onClick={props.onButtonPress}>
                  {props.buttonText ? props.buttonText : "Go to Home"}
                </Button>}
              <div className="mt-2" style={{ opacity: 0 }}>
                <Button color="link trans-fl-cancel-btn">
                  Cancel transaction?
                    </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
export default PageLoadingBlock;
