// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// export default {
//   apiKey: "AIzaSyDzRYDhtVZU7gZMnAupntpgvZnP0Ke2BE8",
//     authDomain: "bigbargains-f48c3.firebaseapp.com",
//     projectId: "bigbargains-f48c3",
//     storageBucket: "bigbargains-f48c3.appspot.com",
//     messagingSenderId: "937027681600",
//     appId: "1:937027681600:web:faa519d8883ada688d0ea5",
//     measurementId: "G-R5YM6K6NSE"
  
// };

export default {
  apiKey: "AIzaSyAYac2E_B26mg0SV1f0RKXggIOyZcC3wOI",
  authDomain: "homeopathy-289808.firebaseapp.com",
  databaseURL: "https://homeopathy-289808.firebaseio.com",
  projectId: "homeopathy-289808",
  storageBucket: "homeopathy-289808.appspot.com",
  messagingSenderId: "821156244741",
  appId: "1:821156244741:web:cbc49bf622db4166299b6c",
  measurementId: "G-8HVZZDQWBM"
}
