import React, { useState } from "react";
import "./css/index.scoped.css";
import { AllModal } from "../../../components";
import { useHistory } from "react-router-dom";
import commands from "../../../commands";
import { Button } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import AuthState from "../../../redux/states/auth";
import { rzp_live } from "../../../URLConst";


function SingleOrders(props) {
  const history = useHistory();
  const [ShowCOModal, settt] = useState(false);
  const [ShowPRModal, pRsettt] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  const openProductRatingModal = () => pRsettt(!ShowPRModal);
  const openCancelOrderModal = (prescription) => {
    setCurrentOrder(prescription);
    settt(!ShowCOModal);
  };
  var [processing, setProcessing] = useState(false);
  const { prescriptions } = props;
  const routeToOrderDetails = (orderId) => {
    history.push({
      pathname: `/myprescriptionorder/${orderId}`,
      state: { orderId },
    });
  };
  console.log("HELLO PAYNENT123");
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  async function getRazorPayId(prescription) {
    // loading script
    console.log(prescription.id, "HELLO PAYNENT");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load");
      return;
    }

    const id = await commands.prescription.getRazorPayTransaction(
      prescription.id
    );
    setProcessing(true);
    if (id) {
      // get user data
      const authState = AuthState.get();
      const {
        user: { name, email, phone_number },
      } = authState;
      // Getting the order details back
      const { total, razorpay_order_id } = id;
      const options = {
        // key: process.env.REACT_APP_RAZORPAY_KEY,
        key:rzp_live,
        amount: (total * 100).toString(), // 2000 paise = INR 20, amount in paisa
        name: "Homeo",
        description: "Super Charge Your Health",
        // order_id: razorpay_order_id,
        currency: "INR",
        image: require("./images/logo.png"),
        handler: async (response) => {
          // in test mode only razorpay_payment_id is received
          setProcessing(true);
          const data = {
            // order_creation_id: id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
          const result = await commands.prescription.verifySignature(data);
          if (result?.data?.success) {
            props?.getPrescriptions();
          }
          setProcessing(false);
        },
        prefill: {
          name,
          email,
          contact: phone_number,
        },
        notes: {
          address: "Homeo INDIA.",
        },
        theme: {
          color: "#29af9d",
        },
        modal: {
          ondismiss: () => setProcessing(false),
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      alert("Server error");
    }
  }
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: black;
  `;
  return (
    <div className="profile-pg-inner-wrap bg-white radius-10 mb-4 yt-my-order-wrap yt-cc-ord">
      {processing ? (
        <div className="sweet-loading text-center">
          <BeatLoader
            css={override}
            size={35}
            margin={30}
            color={"#29af9d"}
            loading={true}
          />
          <h2>Processing Your Payment !</h2>
        </div>
      ) : (
        <>
          <div className="profile-pg-inner-wrapper">
            {ShowCOModal && (
              <AllModal
                modalName="CancelPrescription"
                prescription={currentOrder}
                {...props}
              />
            )}
            <div className="d-flex flex-wrap align-items-center justify-content-between yt-my-order-tdet-wrap">
              <div className="d-flex align-items-center flex-wrap profile-mo-dt-wrap">
                <div className="order-number-wrap">
                  <span className="order-tag">Prescription number : </span>
                  <span className="order-tag-val">{prescriptions.id}</span>
                </div>
                <div className="order-bdr-between" />
                <div className="order-date-wrap">
                  <span className="order-tag">Uploaded on : </span>
                  <span className="order-tag-val">
                    {moment(prescriptions.created_at).format(
                      "ddd,MMMM Do YYYY"
                    )}
                  </span>
                </div>
                {prescriptions?.delivery_slot && (
                  <>
                    <div className="order-bdr-between" />
                    <div className="order-date-wrap">
                      <span className="order-tag">Scheduled Delivery : </span>
                      <span className="order-tag-val">
                        {prescriptions?.delivery_slot}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="text-right">
                {prescriptions.status === "Rejected" ||
                prescriptions.status === "Cancelled" ? (
                  <span
                    style={{
                      color: "var(--color-red)",
                      fontSize: "28px",
                      verticalAlign: "sub",
                    }}
                  >
                    &#8226;
                  </span>
                ) : (
                  <span
                    style={{
                      color: "var(--color-green)",
                      fontSize: "28px",
                      verticalAlign: "sub",
                    }}
                  >
                    &#8226;
                  </span>
                )}{" "}
                &nbsp;
                {_.capitalize(prescriptions.status)}
              </div>
            </div>
            {prescriptions?.prescription_items?.map((item, index) => (
              <>
                <div
                  className={
                    index >= 1
                      ? "py-3 d-flex align-items-center yt-order-wrapper-box w3-ripple  yt-border-order"
                      : "py-3 d-flex align-items-center yt-order-wrapper-box w3-ripple"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => routeToOrderDetails(prescriptions.id)}
                >
                  {/* <div className="od-product-img p-1">
                <img
                  src={
                    require("./images/order-product-img.png")
                  }
                  className="img-fluid"
                />
              </div> */}
                  <div className="d-flex align-items-center">
                    <div className="order-product-info">
                      <h2
                        className="pp-order-product-ttl mt-0"
                        onClick={() => routeToOrderDetails(prescriptions.id)}
                      >
                        {item.prescription_text}
                      </h2>
                      {["Payment", "Paid", "Delivered"].includes(
                        prescriptions?.status
                      ) && (
                        <span className="op-order-product-price align-self-end">
                          <span className="order-product-price">
                            <NumberFormat
                              value={item?.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              thousandsGroupStyle="lakh"
                            />{" "}
                            / per
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="order-product-quanity text-center text-sm-right">
                    <ul className="p-0 order-ul-list-none m-0 yt-qt-prc d-flex flex-wrap">
                      <li className="op-order-quantity mb-3">
                        Quantity : &nbsp;
                        <span className="ord-product-quantity">
                          {item.quantity}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ))}
            {prescriptions?.prescription_images?.map((item, index) => (
              <>
                <div
                  className={
                    index >= 1
                      ? "py-3 d-flex align-items-center yt-order-wrapper-box w3-ripple  yt-border-order"
                      : "py-3 d-flex align-items-center yt-order-wrapper-box w3-ripple"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => routeToOrderDetails(prescriptions.id)}
                >
                  <div className="od-product-img p-1">
                    <img src={item?.prescription_image} className="img-fluid" />
                  </div>
                </div>
              </>
            ))}
            {["Payment", "Paid", "Delivered"].includes(
              prescriptions?.status
            ) && (
              <>
                {" "}
                <div className="mt-1 text-right">
                  <span className="op-order-product-price align-self-end total-tax">
                    <span className="order-product-price">
                      {/* {item.data?.currency}{' '} */}
                      <span className="oneTotal">Total Tax: </span>
                      {/* {config.currency}{''} */}
                      {/* {parseFloat(prescriptions?.total || 0)} */}
                      <NumberFormat
                        value={prescriptions?.total_tax}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                        thousandsGroupStyle="lakh"
                      />
                    </span>
                  </span>
                </div>
                {prescriptions?.delivery_charges > 0 && (
                  <div className="mt-1 text-right">
                    <span className="op-order-product-price align-self-end total-tax">
                      <span className="order-product-price">
                        <span className="oneTotal">Delivery Charges: </span>

                        <NumberFormat
                          value={prescriptions?.delivery_charges}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₹"}
                          thousandsGroupStyle="lakh"
                        />
                      </span>
                    </span>
                  </div>
                )}
                <div className="mt-1 text-right">
                  <span className="op-order-product-price align-self-end">
                    <span className="order-product-price">
                      {/* {item.data?.currency}{' '} */}
                      <span className="oneTotal">Total Amount: </span>
                      {/* {config.currency}{''} */}
                      {/* {parseFloat(prescriptions?.total || 0)} */}
                      <NumberFormat
                        value={prescriptions?.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                        thousandsGroupStyle="lakh"
                      />
                    </span>
                  </span>
                </div>
              </>
            )}
          </div>
          {["Accepted", "In progress", "Payment"].includes(
            prescriptions?.status
          ) && (
            <>
              <div className="w3-border my-3" />
              <div className="text-right">
                {prescriptions?.status === "Payment" && (
                  <>
                    <Button
                      color="link od-payment-btn"
                      onClick={() => getRazorPayId(prescriptions)}
                    >
                      Make Payment
                    </Button>
                    &nbsp; |{" "}
                  </>
                )}
                <Button
                  color="link od-cancel-btn"
                  onClick={() => openCancelOrderModal(prescriptions)}
                >
                  Cancel Prescription
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function OrderDetails(props) {
  return (
    <div className="order-main-wrapper">
      {props.prescriptions.map((prescription, index) => (
        <SingleOrders {...props} prescriptions={prescription} />
      ))}
    </div>
  );
}

function NoOrder() {
  const history = useHistory();
  const routeToprescription = () => {
    let path = "/prescription";
    history.push(path);
  };

  return (
    <div className="profile-pg-inner-wrap profile-pg-inner-no-order p-3 bg-white radius-10 mb-4">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-pg-order-main-wrap text-center ">
          <img
            src={require("./images/no-order-icn.png")}
            className="img-fluid  mb-5"
          />
          <div className="pp-sa-order-wrap mb-5 mt-2">
            <h2 className="pp-od-no-ttl mt-0 mb-3">No any Prescriptions</h2>
            <p className="pp-od-no-text mb-0">
              You haven’t uploaded any prescription, upload now
            </p>
          </div>
          <Button
            color=" pp-no-order-btn py-3 px-3"
            onClick={routeToprescription}
          >
            Upload Prescriptions
          </Button>
        </div>
      </div>
    </div>
  );
}

function MyPrescriptionPage(props) {
  return (
    <>
      {props.prescriptions && props.prescriptions.length > 0 ? (
        <OrderDetails prescriptions={props.prescriptions} {...props} />
      ) : (
        <NoOrder />
      )}
    </>
  );
}
export default MyPrescriptionPage;
