import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { AllModal } from "../../../components";
import { FaLongArrowAltLeft } from "react-icons/fa";
import moment from "moment";
import _ from "lodash";
import lang from "../../../language";
import NumberFormat from "react-number-format";
function PrescriptionOrderDetailsPage(props) {
  const orederDetailLang = new lang("myOrderDetailScreen");
  const [ShowCOModal, settt] = useState(false);
  const history = useHistory();

  const routeToProfile = () => {
    let path = "/profile/myprescriptions";
    history.push(path);
  };
  function getAddressString() {
    if (props?.prescriptionOrder?.prescription_addresses) {
      return `${
        props?.prescriptionOrder?.prescription_addresses[0]?.address
      }, ${
        props?.prescriptionOrder?.prescription_addresses[0]?.address_line_2
      }, ${props?.prescriptionOrder?.prescription_addresses[0]?.city}, ${
        props?.prescriptionOrder?.prescription_addresses[0]?.state
      }, ${props?.prescriptionOrder?.prescription_addresses[0]?.zip_code}`;
    }
  }
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="pageroute hc-breadcrumbs my-3">
            <Link to="/" className="hc-home">
              Home {">"}
            </Link>
            <Link to="/profile" className="hc-mid">
              {" "}
              Profile {">"}
            </Link>{" "}
            <Link to="/profile/myprescriptions" className="hc-mid">
              {" "}
              My Prescriptions {">"}
            </Link>{" "}
            <span className="currpage hc-current">Order Details</span>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} lg={12} className="px-3 col-xxl-9">
          <div className="od-cm-col-pad">
            <div className="hc-beckfrom-ct-page d-flex align-items-center hc-mb-30">
              <FaLongArrowAltLeft
                className="hcp-back-arrow"
                onClick={() => {
                  routeToProfile();
                }}
              />{" "}
              <span className="pl-2 hc-back-tag">
                Prescription Order Details
              </span>
            </div>
            <div className="order-details-main-wrapper bg-white radius-10 mt-3 hc-mb-80">
              <div className="d-flex flex-wrap justify-content-between yt-sp-my-order-tdet-wrap">
                <div className="d-flex align-items-center flex-wrap sinlge-mo-dt-wrap ">
                  <div className="order-number-wrap">
                    <span className="order-tag">Prescription Number : </span>
                    <span className="order-tag-val">
                      {props?.prescriptionOrder?.id}
                    </span>
                  </div>
                  <div className="order-bdr-between" />
                  <div className="order-date-wrap">
                    <span className="order-tag">Uploaded on : </span>
                    <span className="order-tag-val">
                      {moment(props?.prescriptionOrder.created_at).format(
                        "ddd,MMMM Do YYYY"
                      )}
                    </span>
                  </div>
                  {props?.prescriptionOrder?.delivery_slot && (
                    <>
                      <div className="order-bdr-between" />
                      <div className="order-date-wrap">
                        <span className="order-tag">Scheduled Delivery : </span>
                        <span className="order-tag-val">
                          {props?.prescriptionOrder?.delivery_slot}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {props?.prescriptionOrder?.prescription_items?.map(
                (order, index) => (
                  <div className="py-3 d-flex align-items-center mb-3 od-single-prd-details-wrap">
                    <span className="w3-ripple d-flex">
                      <div className="d-flex align-items-center">
                        <div className="order-product-info ">
                          <h2 className="pp-order-product-ttl mt-0">
                            {order?.prescription_text}
                          </h2>
                        </div>
                      </div>
                    </span>
                    {["Payment", "Paid", "Delivered"].includes(
                      props?.prescriptionOrder?.status
                    ) && (
                      <ul className=" order-ul-list-none mb-0 ">
                        <li className="op-order-product-price ">
                          <span className="order-product-price">
                            <NumberFormat
                              value={order?.price || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₹"}
                              thousandsGroupStyle="lakh"
                            />{" "}
                            / per
                          </span>
                        </li>
                      </ul>
                    )}

                    <div>
                      <div className="order-product-quanity text-center text-sm-right">
                        <ul className="p-0 order-ul-list-none m-0 ">
                          <li className="op-order-quantity mb-3">
                            Quantity:{" "}
                            <span className="ord-product-quantity">
                              {order?.quantity}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              )}
              {props?.prescriptionOrder?.prescription_images?.map(
                (order, index) => (
                  <div className="py-3 d-flex align-items-center mb-3 od-single-prd-details-wrap">
                    <span className="w3-ripple d-flex">
                      <div className="od-product-img p-1 d-flex align-items-center justify-content-center">
                        <img
                          src={order.prescription_image}
                          className="img-fluid"
                        />
                      </div>
                    </span>
                  </div>
                )
              )}
              {["Payment", "Paid", "Delivered"].includes(
                props?.prescriptionOrder?.status
              ) && (
                <>
                  <div className="py-3 d-flex align-items-center mb-3 od-single-prd-details-wrap d-flex">
                    <span className="w3-ripple d-flex">
                      <div className="d-flex align-items-center">
                        <div className="order-product-info ">
                          <h2 className="pp-order-product-ttl mt-0 my-tax">
                            Total Tax :{" "}
                            <span className="myTotalspan">
                              <NumberFormat
                                value={props?.prescriptionOrder?.total_tax || 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                                thousandsGroupStyle="lakh"
                              />
                            </span>
                          </h2>
                          {props?.prescriptionOrder?.delivery_charges > 0 && (
                            <h2 className="pp-order-product-ttl mt-0 my-tax">
                              Delivery Charges :{" "}
                              <span className="myTotalspan">
                                <NumberFormat
                                  value={
                                    props?.prescriptionOrder
                                      ?.delivery_charges || 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                  thousandsGroupStyle="lakh"
                                />
                              </span>
                            </h2>
                          )}
                          <h2 className="pp-order-product-ttl mt-0">
                            Total Amount :{" "}
                            <span className="myTotalspan">
                              <NumberFormat
                                value={props?.prescriptionOrder?.total || 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                                thousandsGroupStyle="lakh"
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </span>
                  </div>
                </>
              )}

              <Row>
                <Col md={12}>
                  <div className="order-details-status-bar py-3 my-3">
                    <h2 className="order-details-sub-title">Order Status</h2>
                    <ul className="pl-2 order-ul-list-none mb-0 ml-3 order-details-status-wrap">
                      <div>
                        <li>
                          <img
                            alt="status check"
                            src={require("./images/statuss-check.png")}
                            className="order-details-status-icn"
                          />
                          <div className="order-step-1 order-st-otw">
                            <h4 className="d-flex align-items-center">
                              {props?.prescriptionOrder.status}
                            </h4>
                            <p className="order-details-message">
                              {moment(
                                props?.prescriptionOrder?.created_at
                              ).format("ddd,MMMM Do YYYY")}
                            </p>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </Col>
              </Row>
              {props?.prescriptionOrder?.prescription_addresses?.length > 0 && (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="order-details-shipping-barmy-3">
                        <h2 className="order-details-sub-title">
                          {orederDetailLang.get(
                            "shippingAddress",
                            "Shipping Address"
                          )}
                        </h2>
                        <div className="order-shipping-address-wrap">
                          <h2 className="order-details-address-type">
                            {_.capitalize(
                              props?.prescriptionOrder
                                ?.prescription_addresses[0]?.name
                            )}
                          </h2>
                          <p className="order-details-address-details">
                            {getAddressString()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="order-details-contact">
                          {orederDetailLang.get("phoneNumber", "Phone Number")}:{" "}
                          <span className="order-details-phone">
                            {
                              props?.prescriptionOrder
                                ?.prescription_addresses[0]?.phone_number
                            }
                          </span>
                        </div>
                        <div className="order-cancel-wrap text-right" />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PrescriptionOrderDetailsPage;
