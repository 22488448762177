import React, { useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  HeroBanner,
  Header,
  AppStoreBanner,
  ProductCard,
  CollectionCard,
  ProfileComponent,
  ErrorOccuredBlock,
  PageLoadingBlock,ThreePromo2,
  ThreeImageBanner
} from "../../components";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./css/index.scoped.css";
import { FaLongArrowAltLeft, FaCamera } from "react-icons/fa";
import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";

function ProfilePage(props) {
  const authState = AuthState.get();
  const cacheState = CacheState.get();
  const [wishlist, setWishlist] = useState(undefined);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [helpCenterData, setHelpCenterData] = useState(undefined);

  useEffect(() => {
    if (authState.user.is_guest) {
      props.history.push("/login");
    }
    getWishlistProducts();
    getMyOrders();
    getMyPrescriptions();
  }, [])

  function getWishlistProducts() {
    const serviceType = serviceTypes.getWishlist(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/")
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setWishlist(undefined); getWishlistProducts(); }
            });
          }
        } else if (response) {
          if (typeof response.data === "object") {
            setWishlist(response.data.data)
            setErrorData(null)
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setWishlist(undefined); getWishlistProducts(); }
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getMyOrders() {
    const serviceType = serviceTypes.getMyOrdersList(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/")
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setOrders(undefined); getMyOrders(); }
            });
          }
        } else if (response) {
          if (typeof response.data.data === "object") {
            setOrders(response?.data?.data?.order);
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setOrders(undefined); getMyOrders(); }
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getMyPrescriptions() {
    const serviceType = serviceTypes.getMyPrescriptionsList(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The prescription you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/")
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setPrescriptions(undefined); getMyPrescriptions(); }
            });
          }
        } else if (response) {
          if (typeof response.data.data === "object") {
            setPrescriptions(response?.data?.data);
            console.log(response?.data?.data,"MYPRESCRIPTIONS")
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => { setLoading(true); setErrorData(null); setPrescriptions(undefined); getMyPrescriptions(); }
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  return (
    <div>
      <Header onProps={props} />
      {/* <HeroBanner />
      <CollectionCard
        collections={
          cacheState.homepage?.categories 
        }
      /> */}
      { wishlist ?
        <ProfileComponent onProps={props} wishlist={wishlist} order={orders} prescriptions={prescriptions} getWishlist={getWishlistProducts} getOrders={getMyOrders} getPrescriptions={getMyPrescriptions}/> : <Fragment>
          {!loading && <ErrorOccuredBlock title={errorData?.title || "Oh Noes!, Error Occured"} message={errorData?.message || "Unknown Error Occured."} buttonText={errorData?.buttonText || "Go to Home"} onButtonPress={(errorData?.onButtonPress) || (() => props.history.push("/"))} />}
          {loading && <PageLoadingBlock title={"Please wait..."} message={"Your Profile is loading!"} buttonText={"Go to Home"} onButtonPress={() => props.history.push("/")} />}
        </Fragment>
      }
      {/* App Store Banner */}
      {/*Three Promo 2*/}
      <ThreePromo2 />
      {/*Three Promo 2 End*/}
       
      <ThreeImageBanner />
      {/* App Store Banner */}
      <ProductCard
        products={cacheState.homepage?.latest_products}
        name="New Products"
        onViewMore={() =>
          props.history.push("/shop?&order_field=latest&page=1&per_page=15")
        }
      />
      <AppStoreBanner />
      {/* App Store Banner End*/}
      <Footer />
    </div>
  );
}
export default ProfilePage;
