import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import "./css/index.scoped.css";
import { useHistory } from "react-router-dom";
import HeaderBanner from "./images/herobanner.jpg";
import commands from "../../commands";
import SearchData from "./SearchData.js";
import { BiCurrentLocation } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import _ from "lodash";
import parse from "html-react-parser";
import CacheState from "../../redux/states/cache";

function HeroBanner(props) {
  var mybanner2 = commands?.cache?.getPositionedWebBanners({position: 1})
  var sortedBanner =  _.sortBy(mybanner2[0]?.images,["Position"]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [SearchDropDown, setSearchDropDown] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [quickResults, setQuickResults] = useState([]);
  const routeToAll = (value) => {
    if (value !== undefined) {
      let path = "/" + value;
      window.scrollTo(0, 0);
      history.push(path);
    } else {
      let path = "/";
      window.scrollTo(0, 0);
      history.push(path);
    }
  };

  function search() {
    if (searchQuery != "") {
      history.push(`/shop?filter_query=${searchQuery}`);
    }
  }

  function quickSearch() {
    if (searchQuery != "") {
      commands.products
        .getQuickSearch({ query: searchQuery })
        .then((results) => {
          setQuickResults(results || []);
        });
    }
  }

  function getRecentSearch() {
    commands.products.getRecentSearches().then((results) => {
      setRecentSearches(results || []);
    });
  }

  useEffect(() => {
    if (searchQuery == "") {
      getRecentSearch();
    }
  }, [searchQuery]);

  var ImageHead = {
    backgroundImage: `url(${HeaderBanner})`,
  };
  const OpenSearchDropDown = () => {
    setSearchDropDown(!SearchDropDown);
  };
  return (
    <div className="hc-hero-banner" style={{backgroundImage:`url(${sortedBanner[0]?.image})`}}>
      <div className="hero-content">
        <h3 className="banner-title">
          Super Charge your health with top Immunity Medicines
        </h3>
        <div className="yt-head-hide-srch-col">
          <div className="searchcontainer header-search-field-wrap">
            <input
              type="text"
              placeholder="Search Medicine"
              className="searchinput"
              onClick={() => setSearchDropDown(true)}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  search();
                  setSearchDropDown(false);
                } else quickSearch();
              }}
              onFocus={() => {
                setSearchDropDown(true);
                getRecentSearch();
              }}
            />
            <BiCurrentLocation className="location-icon" />
            <AiOutlineSearch
              className="search-icon w3-ripple w3-hover-opacity"
              onClick={() => search()}
            />
            {SearchDropDown && searchQuery != "" && (
              <SearchData
                hideSearch={() => setSearchDropDown(false)}
                results={quickResults}
                isQuickResults={true}
              />
            )}
            {SearchDropDown &&
              searchQuery == "" &&
              Array.isArray(recentSearches) &&
              recentSearches.length > 0 && (
                <SearchData
                  hideSearch={() => setSearchDropDown(false)}
                  results={recentSearches}
                />
              )}
          </div>
        </div>

        <p className="banner-subtitle">- Or -</p>
        <Button
        color="transparent"
        className="hc-hero-btn"
        onClick={() => {
          routeToAll("prescription");
        }}
      >
        Upload Prescription
      </Button>
      </div>
    </div>
  );
}

export default HeroBanner;
