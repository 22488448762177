import React, { useState } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button } from "reactstrap";
import TwoImg from "./images/two.png";
import { useHistory } from "react-router";
import commands from "../../commands";
import _ from "lodash";
import parse from "html-react-parser";

function SingleImageBanner() {
  // var mybanner2 = commands.cache.getPositionedWebBanners({position: 2})
  // var sortedBanner = _.sortBy(mybanner2[0]?.images,["Position"]);
  const BackgroundTwo = TwoImg;
  var ImageTwo = {
    backgroundImage: `url(${BackgroundTwo})`,
  };
  const history = useHistory();
  return (
    <section >
      <div className="shopnow yt-three-promo-main">
        <div className="yt-cm-three-main-row d-flex flex-wrap">
          <div className="yt-cm-three-col">
            <div className="yt-first-bottom-banner hover-me" style={ImageTwo}>
              <div className="yt-3banner-wrapper yt-top-2 positionBanner">
                <h2 className="yt-3banner-title my-0">
                Disposable Masks
                </h2>
                {/* <h2 className="yt-3banner-title my-0">
                  to the world of modest wear
                </h2> */}
                <h4 className="yt-3banner-sub-title ">Don’s Miss Special Offers</h4>
                <Button
                  color=" yt-3banner-sp-btn py-3 mt-3"
                  onClick={() => location.history.push("/shop")}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// function ThreeInOneBanner() {
//  var banners = commands.cache.getPositionedBanners({ position: 2 });
// return banners.map((item) => parse(item.content + "<br />"));
// }

export default SingleImageBanner;
