import React, { useState } from "react";
import ExistSearch from "./ExitSearch";
import NotsearchFound from "./NotsearchFound";
export default function SearchData(props) {
  const [SearchDataStatus, setSearchDataStatus] = useState(true);

  var filtered = [];
  if (Array.isArray(props.results)) {
    filtered = props.results; //props.results.filter((item)=>item.class_name==="Product");
  }
  return filtered.length > 0 ? (
    <ExistSearch {...props} results={filtered} />
  ) : (
    <NotsearchFound {...props} />
  );
}
