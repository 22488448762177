import React, { useState, Fragment } from "react";
import "./css/index.scoped.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import commands from "../../../commands";
import lang from "../../../language";
import * as validationSchemas from "../../../validationSchemas";
import { Formik } from "formik";

function CancelPrescription(props) {
  const cancelorderlang = new lang("myOrderScreen");
  const [modal, setModal] = useState(true);
  const [deleteorder, SetDelete] = useState(false);
  const toggle = () => setModal(!modal);
  const ConfirmDelete = (values) => {
    commands.prescription.cancelPrescription(
      props?.prescription?.id,
      values.cancelReason,
      onSuccess
    );
  };
  const onSuccess = () => {
    SetDelete(true);
    props.getPrescriptions();
  };
  return (
    <div>
      <Formik
        initialValues={{ cancelReason: "" }}
        // onSubmit={onSubmit}
        validationSchema={validationSchemas.cancelOrder}
        onSubmit={ConfirmDelete}
      >
        {({
          values,
          handleChange,
          errors,
          setFieldTouched,
          touched,
          isValid,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="cm-small-modal-4"
            centered={true}
          >
            <ModalHeader toggle={toggle} className="co-title-bar  border-0">
              <span>
                {deleteorder ? "Prescription Cancelled" : "Cancel Prescription"}
              </span>
            </ModalHeader>
            <ModalBody className="py-3">
              <div className="text-center co-body-text mb-4">
                {deleteorder
                  ? "Your prescription has been cancelled successfully!"
                  : "Are you sure you want to cancel ?"}
              </div>
              {!deleteorder && (
                <Form>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="product-review-description"
                      id="product-review-description"
                      placeholder="Write cancellation reason"
                      className="p-3"
                      value={values.cancelReason}
                      onChange={(e) =>
                        setFieldValue("cancelReason", e.target.value)
                      }
                    />
                    {errors.cancelReason && touched.cancelReason && (
                      <div style={{ color: "#e65e52" }}>
                        {errors.cancelReason}
                      </div>
                    )}
                  </FormGroup>
                </Form>
              )}
            </ModalBody>

            <ModalFooter className="co-bottom-bar  p-1 d-flex">
              {deleteorder ? (
                <Button
                  color=" pp-co-btn-modal p-3 pp-co-btn-light-grey w-100"
                  onClick={() => setModal(false)}
                  block
                >
                  {cancelorderlang.get("ok", "OK")}
                </Button>
              ) : (
                <Fragment>
                  <Button
                    color=" pp-co-btn-modal p-3 pp-co-btn-light-grey"
                    onClick={toggle}
                    block
                  >
                    {cancelorderlang.get("cancel", " Cancel")}
                  </Button>
                  <span className="yt-form-spacer" />
                  <Button
                    color=" pp-co-btn-modal p-3 pp-co-btn-dark-grey"
                    // onClick={ConfirmDelete}
                    onClick={handleSubmit}
                    block
                  >
                    {cancelorderlang.get("yesConfirm", "Yes, Confirm")}
                  </Button>
                </Fragment>
              )}
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </div>
  );
}
export default CancelPrescription;
