import React, { useRef } from "react";
import "./css/index.scoped.css";
import { Row, Col, Button, Container } from "reactstrap";
import Carousel from "react-elastic-carousel";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Ripple from "react-ripples";
import { useHistory } from "react-router";
import langg from "../../language";

function TitleBar(props) {
  const history = useHistory();
  const lang = new langg("common");
  if (props.name != undefined) {
    return (
      <div className="yt-produstslider-info d-flex justify-content-between align-items-center">
        <h4 className="yt-comonent-top-title my-0">{props.name}</h4>
        <div className="yt-comonent-link">
          <Button
            color="link yt-component-more px-0"
            onClick={() => history.push("/shop")}
          >
            {lang.get("viewMore", "View More")}
          </Button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function CollectionCard(props) {
  const history = useHistory();
  let carousel = useRef();
  let Breakpoints = [
    { width: 320, itemsToShow: 2 },
    { width: 730, itemsToShow: 3 },
    { width: 1300, itemsToShow: 10 },
  ];
  const collections = props.collections;
  return Array.isArray(collections) && collections.length > 0 ? (
    <section className="bg-white">
      <div className="yt-main-wrap">
        <div className="product yt-collection-component yt-collection-wrap  py-4 ">
          <Carousel
            itemsToShow={10}
            pagination={true}
            showArrows={false}
            ref={(ref) => (carousel = ref)}
            breakPoints={Breakpoints}
            className="collection-card-slider"
          >
            {collections.map((item, idx) => (
              <Ripple
                key={idx}
                onClick={() => history.push(`/shop?category_id[]=${item.id}`)}
                className="item_box"
              >
                <div className="yt-collection-item w3-ripple">
                  <img
                    src={item?.image || "/images/thumb/missing.png"}
                    className="img-fluid yt-coll-img"
                    alt="homeo"
                  />
                </div>
                <div className="yt-collection-title">{item.name}</div>
              </Ripple>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  ) : null;
}

export default CollectionCard;
