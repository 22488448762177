import React, { useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
// import { Formik, Form, Field } from "formik";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import { postUpdate } from "../../../Barriers/apiHelper";
import { useHistory } from "react-router";
import commands from "../../../commands";
import * as validationSchemas from "../../../validationSchemas";
import { Formik } from "formik";

import { CgSpinner } from "react-icons/cg";

function SizeChartModal(props) {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const userData = useSelector((state) => state.logInReducer);
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="cm-small-modal-6"
      >
        <ModalHeader toggle={props.toggle} className="size-chart-title-bar p-4">
          <span>Size Chart</span>
        </ModalHeader>
        <ModalBody className="py-4 px-5 yt-add-modal-body">
          <div className="ad-addr-body-wrap text-center">
            <img src={props?.image} className="img-fluid w-100" />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default SizeChartModal;
{
  /* <FormGroup className="yt-number-filed">
<div className="profile-form-field fields-active error">
  <span className="profile-form-tag">Pin Code</span>
  <Input
    className="py-2 "
    type="number"
    name="phoneno"
    id="phoneno"
  />
</div>
</FormGroup> */
}
