import React, { useRef, useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { FaRegHeart } from "react-icons/fa";
import Carousel from "react-elastic-carousel";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { FavouriteProductSet } from "../../components";
import { useHistory } from "react-router";
import commands from "../../commands";
import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import langg from "../../language";
import NumberFormat from "react-number-format";

function TitleBar(props) {
  const lang = new langg("common");
  if (props.name != undefined) {
    return (
      <div className="yt-produstslider-info d-flex justify-content-between align-items-center">
        <h4 className="yt-comonent-top-title my-0 ml-2">{props.name}</h4>
        <div className="yt-comonent-link">
          <Button
            color="link yt-component-more px-0"
            onClick={props.onViewMore}
          >
            View All
          </Button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function CategoryCard(props) {
  const history = useHistory();
  const state = AuthState.get();
  const [products, setProducts] = useState(undefined);
  const lang = new langg("common");

  let carousel = useRef();
  let p = 0;
  let Breakpoints = [
    { width: 320, itemsToShow: 2, itemsToScroll: 2 },
    { width: 730, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1300, itemsToShow: 5 },
  ];

  useEffect(() => {
    // if(props.name=="Today’s Deals"){
    if (props.products?.length > 0) {
      setProducts(props.products);
    }
    // }
  }, [props]);

  useEffect(() => {
    // console.log(products, "products");
  }, [products]);

  const productDetails = (a) => {
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  function addToWishlist(product) {
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: "Product added to wishlist successfully",
              type: "success",
            },
          ]);
          commands.cache.getHomeProducts();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  function removeFromWishlist(product) {
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: "Product removed from wishlist successfully",
              type: "danger",
            },
          ]);
          commands.cache.getHomeProducts();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  function getList() {
    var list = [];
    var lolist = [];

    products.forEach((product, index) => {
      var percentageValue = parseInt(
        ((product.price - product.sale_price) / product.price) * 100
      );
      if (
        !(
          Array.isArray(product.product_variants) &&
          product.product_variants.length > 0
        )
      ) {
        list.push(
          <div className="yt-item-slider">
            <div className="product yt-product-slider-cpnt text-center">
              <div className="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
                {product.on_sale && (
                  <div className="yt-product-off text-center p-1">
                    {percentageValue < 1
                      ? "sale"
                      : "upto " +
                        parseInt(
                          ((product.price - product.sale_price) /
                            product.price) *
                            100
                        ) +
                        "% Off"}
                  </div>
                )}
                {!product.on_sale && <div className="text-center p-1" />}
              </div>
              <img
                src={
                  product?.images?.length > 0
                    ? product?.images[0]?.image
                    : "/images/thumb/missing.png"
                }
                className="img-fluid yt-td-product-img prodimage w3-ripple "
                alt="homeo"
                onClick={() => productDetails(product)}
              />
              <div className="mt-4 yt-product-title text-center">
                {product.name}
              </div>
            </div>
          </div>
        );
      }

      product?.product_variants?.forEach(
        (x, i) =>
          x.is_master &&
          list.push(
            <div className="yt-item-slider" popkey={index + "-" + i}>
              <div className="product yt-product-slider-cpnt text-center">
                <div className="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
                  {x.on_sale && (
                    <div className="yt-product-off text-center p-1">
                      -
                      {parseInt(
                        ((x.actual_price - x.sale_price) / x.actual_price) * 100
                      )}
                      %
                    </div>
                  )}
                  {!x.on_sale && <div className="text-center p-1" />}
                </div>
                <img
                  src={
                    x.images?.length > 0
                      ? x?.images[0]?.image
                      : product?.images[0]?.image
                  }
                  className="img-fluid yt-td-product-img prodimage w3-ripple "
                  alt="homeo"
                  onClick={() => productDetails(product)}
                />
                <div className="mt-4 yt-product-title text-center">
                  {product?.name}
                </div>
              </div>
            </div>
          )
      );
      list = [...list, ...lolist];
    });

    return list;
  }

  var list = [];
  if (products && Array.isArray(products) && products.length > 0) {
    list = getList();
  }
  const showCard = list.length > 0;

  return products && Array.isArray(products) && products.length > 0 ? (
    <section
      className="hp-product-slider"
      style={showCard ? {} : { display: "none" }}
    >
      <Container>
        <TitleBar name={props.name} onViewMore={props.onViewMore} />
        <div className="yt-component-wrapper yt-slider-component py-4 mb-3 bg-white">
          <IoIosArrowDropleft
            className="yt-slider-left img-fluid"
            onClick={() => carousel.slidePrev()}
          />
          <IoIosArrowDropright
            className="yt-slider-right img-fluid"
            width="20"
            height="20"
            onClick={() => carousel.slideNext()}
          />
          <Carousel
            itemsToShow={15}
            itemsToScroll={15}
            pagination={false}
            showArrows={false}
            ref={(ref) => (carousel = ref)}
            breakPoints={Breakpoints}
          >
            {list}
          </Carousel>
        </div>
      </Container>
    </section>
  ) : null;
}

export default CategoryCard;
