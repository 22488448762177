import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import {
  Header,
  Footer,
  ThreeInOneBanner,
  AppStoreBanner,
} from "../../components";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchUUID, setGuestStatus } from "../../redux/actions/loginActions";
import { get, getProducts, post } from "../../Barriers/apiHelper";
import { v4 as uuidv4 } from "uuid";
import service, { serviceTypes } from "../../services";
import useSWR from "swr";
import langg from "../../language";
import { Link } from "react-router-dom";
function Categories(props) {
  const styles = {
    item: {
      margin: "0 10px",
    },
  };

  const dispatch = useDispatch();
  const [products, setHomeProduts] = useState([]);
  const [featureProducts, setFeaturedProducts] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const state = useSelector((state) => state.logInReducer);
  const cacheState = useSelector((state) => state.cache);
  const cartState = useSelector((state) => state.cartReducer);
  const [img, setImg] = useState(
    require("./images/muhammad-fajarruzaman-S_OImJLLc_Y-unsplash.png")
  );
  const lang = new langg("homeScreen");

  useEffect(async () => {
    if (!localStorage.getItem("UUID")) {
      let uuId = uuidv4();
      dispatch(fetchUUID(uuId));
      localStorage.setItem("UUID", uuId);
    }
    if (Object.keys(state.loginData).length > 0 && state.uuId.length > 0) {
      callProducts(state.loginData.token, state.uuId);
    }
    if (state.uuId.length > 0) {
      if (state.guest_user) await createGuestUser(state.uuId);
    }
  }, []);

  // useEffect(() => {}, [products]);

  function callProducts(token, uuid) {
    let headers = {
      headers: {
        Authorization: token.token_type + " " + token.access_token,
      },
    };
    getProducts(
      `/products/get_all_products?page=1&per_page=15&uuid=${uuid}`,
      headers
    )
      .then((res) => {
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
        console.log("err", Err);
      });
  }
  const createGuestUser = (uuid) => {
    let data = {
      uuid: uuid,
    };
    post(`/users/create_guest_user`, data)
      .then((res) => {
        callProductsUUID(res.data.data.uuid);
        dispatch(setGuestStatus(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callProductsUUID = (uuid) => {
    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    }
    get(`/products/get_all_products?page=1&per_page=15&uuid=${uuid}`, {
      headers,
    })
      .then((res) => {
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
      });
  };

  function onClickHeart(product) {
    const data = {
      product_id: product.id,
      uuid: localStorage.getItem("UUID"),
      userId: state.user.id,
    };
    let serviceType;
    let notificationMsg;
    if (product.is_wishlisted) {
      serviceType = serviceTypes.removeFromWishlist(data);
      notificationMsg = `${product.name} has been removed to wishlist`;
    } else {
      serviceType = serviceTypes.addToWishlist(data);
      notificationMsg = `${product.name} has been added to wishlist`;
    }
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message, type: "warning" }]);
        } else if (response) {
          window.notify([{ message: notificationMsg, type: "info" }]);
          callProductsUUID(localStorage.getItem("UUID"));
        }
      })
      .finally(() => {});
  }

  return (
    <div>
      <Header onProps={props} />

      <Container>
        <div className="pageroute">
          <Link to="/">
            <span
              className="cart-pg-home w3-hover-opacity"
              style={{ cursor: "default" }}
            >
              Home
            </span>
          </Link>
          {" > "}
          <span className="currpage">Shop</span>
        </div>
        <h1 className="yt-profile-mb-ttl">{props.match.params.category}</h1>
        <p className="category-description">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
        <div className="categorypage">
          <Row>
            <Col md={3}>
              <div className="d-flex align-items-center mb-5 yt-profile-img-nm-wrap">
                <div className="img-upload d-flex align-items-center justify-content-center">
                  <img alt="Profile Pics" src={img} className="img-fluid" />
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex align-items-center mb-5 yt-profile-img-nm-wrap">
                <div className="img-upload d-flex align-items-center justify-content-center">
                  <img alt="Profile Pics" src={img} className="img-fluid" />
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex align-items-center mb-5 yt-profile-img-nm-wrap">
                <div className="img-upload d-flex align-items-center justify-content-center">
                  <img alt="Profile Pics" src={img} className="img-fluid" />
                  <div className="pos-third-overlay w3-display-bottomleft px-5 py-4">
                    <div className="h-100">
                      <div className="pb-2 pos-three-title">
                        Fashion and Trend come together
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex align-items-center mb-5 yt-profile-img-nm-wrap">
                <div className="img-upload d-flex align-items-center justify-content-center">
                  <img alt="Profile Pics" src={img} className="img-fluid" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/*Three Banner Section */}
      <ThreeInOneBanner />
      {/*Three Banner Section */}

      {/* App Store Banner */}
      <AppStoreBanner />
      {/* App Store Banner End*/}
      <Footer />
    </div>
  );
}

export default Categories;
