import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";

export default async function uploadPrescription({
  flow,
  prescription_item,
  address,
  onSuccess,
}) {
  try {
    const cartState = CartState.get();
    const authState = AuthState.get();
    const loginData = authState.loginData;
    const user = authState.user;
    var serviceType = undefined;

    //auth setter part with optional test data for guest
    if (typeof user === "object") {
      serviceType = serviceTypes.uploadPrescription(user?.id);
    } else {
      throw new Error("Login data error, try logout and login again.");
    }

    if (serviceType) {
      if (flow === 1) {
        serviceType.body.prescription.prescription_images = prescription_item;
      } else if (flow === 2) {
        serviceType.body.prescription.prescription_items = prescription_item;
      }
      if (address.isShippingAddressSame) {
        serviceType.body.billing_same_as_shipping = true;
        serviceType.body.delivery_address_id = address.bid;
      } else {
        serviceType.body.billing_same_as_shipping = false;
        serviceType.body.delivery_address_id = address.bid;
        serviceType.body.billing_address_id = address.sid;
      }
    }

    const { response, error } = await service(serviceType);
    if (error) {
      throw new Error(
        error.message || "Error occured in uploading Prescription"
      );
    } else if (response) {
      if (response.data?.success === true) {
        window.notify([
          {
            message:
              response.data?.message ||
              "Your Prescription Uploaded Successfully",
            type: "success",
          },
        ]);
        // CartState.set({ prescription: response.data?.data?.order });
        typeof onSuccess === "function" && onSuccess();
      } else {
        throw new Error(
          error.message || "Error occured in uploading Prescription"
        );
      }
    }
  } catch (error) {
    window.notify([
      {
        message: error.message || "Error occured in uploading Prescription",
        type: "danger",
      },
    ]);
  }
}
