import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";

export default async function cancelPrescription(
  prescriptionId,
  cancelText,
  onSuccess
) {
  try {
    const cartState = CartState.get();
    const authState = AuthState.get();
    const loginData = authState.loginData;
    const user = authState.user;
    var serviceType = undefined;
    console.log(prescriptionId, cancelText, "MYMODALDATA");
    //auth setter part with optional test data for guest
    if (typeof user === "object") {
      serviceType = serviceTypes.cancelPrescription(user?.id, prescriptionId);
    } else {
      throw new Error("Login data error, try logout and login again.");
    }

    if (serviceType) {
      serviceType.body.cancellation_reason = cancelText;
    }

    const { response, error } = await service(serviceType);
    if (error) {
      //console.log("Received data is ", error.message);
      throw new Error(error.message || "Error occured in cancelling order");
    } else if (response) {
      //console.log("Received data is ", response);
      if (response.data?.success === true) {
        //CartState.set({ cart: response.data?.data?.order });
        onSuccess();
      } else {
        throw new Error(error.message || "Error occured in cancelling order");
      }
    }
  } catch (error) {
    window.notify([
      {
        message: error.message || "Error occured in cancelling order",
        type: "danger",
      },
    ]);
  }
}
