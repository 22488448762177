import React from "react";
import "./css/index.scoped.css";
import { useSelector } from "react-redux";
import {
  Header,
  Footer,
  HeroBanner,
  ContactForm,
  CollectionCard,
  AppStoreBanner,
  SingleImageBanner,
} from "../../components";

function ContactUs(props) {
  const cacheState = useSelector((state) => state.cache);
  return (
    <div>
      <Header onProps={props} />
      {/* <HeroBanner />
      <CollectionCard
        collections={
          cacheState.homepage?.categories 
        }
      /> */}

      <ContactForm />

      <SingleImageBanner />
      {/* App Store Banner */}
      <AppStoreBanner />
      {/* App Store Banner End*/}
      <Footer />
    </div>
  );
}

export default ContactUs;
