import React, { useEffect, useState, Fragment, useReducer } from "react";
import "./css/index.scoped.css";
import { ImCancelCircle } from "react-icons/im";
import getLoginValidation from "../Validation schemas/loginValidation";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Label,
  Input,
  FormGroup,
  Table,
} from "reactstrap";
import {
  AllModal,
  LoginComponent,
  PrescriptionQuantityField,
} from "../../components";
import { useHistory, useParams, Link } from "react-router-dom";
import commands from "../../commands";
import { IoIosClose } from "react-icons/io";
import CartState from "../../redux/states/cart";
import Ripple from "react-ripples";
import * as validationSchemas from "../../validationSchemas";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { SiPluscodes } from "react-icons/si";
import classnames from "classnames";
import PageLoadingBlock from "../page-loading-block";

function PrescriptionPageComponent(props) {
  const [PrescriptionImageData, setPrescriptionImage] = useState([]);
  const [executed, setExecution] = useState(false);
  const [ShowCheckout, setShowCheckout] = useState(false);
  const [ShowCart, setShowCart] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);
  const [prescriptionNote, setPrescriptionNote] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [myloader, setLoader] = useState(false);
  const history = useHistory();
  function fileUploader() {
    var fileTag = document.getElementById("prescription-uploader");
    fileTag.addEventListener("change", function() {
      if (!executed) {
        changeImage(this);
        setExecution(true);
      }
    });
    fileTag.click();
  }

  function changeImage(input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function(e) {
        setPrescriptionImage((oldArray, index) => [
          ...oldArray,
          { image: e.target.result },
        ]);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  function zeroTest(element) {
    return element === 0;
  }
  function appendPrescriptionNote() {
    if (prescriptionNote.length === 0) {
      setPrescriptionNote((oldArray, index) => [
        ...oldArray,
        { prescription_text: "", quantity: 1 },
      ]);
    } else {
      if (
        !_.some(_.map(prescriptionNote, "prescription_text"), ["length", 0])
      ) {
        if (prescriptionNote.every(zeroTest)) {
          window.notify([{ message: `Quantity cannot be 0`, type: "danger" }]);
        } else {
          setPrescriptionNote((oldArray, index) => [
            ...oldArray,
            { prescription_text: "", quantity: 1 },
          ]);
        }
      } else {
        window.notify([
          { message: `All notes fields are required`, type: "danger" },
        ]);
      }
    }
  }

  function updatepresctionNote(index, value, mod) {
    let newArr = [...prescriptionNote];
    console.log(newArr[index]);
    if (mod === 1) {
      newArr[index] = {
        prescription_text: value,
        quantity: newArr[index].quantity,
      };
    } else if (mod === 2) {
      newArr[index] = {
        prescription_text: newArr[index].prescription_text,
        quantity: value,
      };
    }
    setPrescriptionNote(newArr);
  }
  function removePrescription(index) {
    setPrescriptionImage((oldArray) =>
      oldArray.filter((item, ind) => ind !== index)
    );
  }
  function removePrescriptionNote(index) {
    setPrescriptionNote((oldArray) =>
      oldArray.filter((item, indx) => indx !== index)
    );
  }
  function proceedToCheckout() {
    if (commands.user.isLoggedIn()) {
      history.push("#address");
    } else {
      history.push("?redirect=/prescription");
      setShowLogin(true);
    }
  }

  useEffect(() => {
    if (history.location.hash === "#address") {
      showCheckoutForm();
    } else {
      showCartForm();
    }
  }, [history.location.hash]);

  function showCartForm() {
    setShowLogin(false);
    setShowCart(true);
    setShowCheckout(false);
  }
  function showCheckoutForm() {
    setShowLogin(false);
    setShowCart(false);
    setShowCheckout(true);
  }
  function CheckoutPrescription() {
    return (
      <>
        <div className="radius-10 bg-white yt-cart-price-lister">
          <Table className="mb-0 cart-prodict-amount " borderless>
            <thead>
              <tr>
                <th>Your Cart</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className=" d-flex table-content-text">MRP Total</span>
                  <p className="item-quantity">
                    Total amount to be paid will be confirmed once your order is
                    generated.
                  </p>
                </td>
                <td>
                  <span className="cart-product-amount-price mt-4">
                    To be decided
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
          <span className="cart-divider" />
          <Table className="cart-prodict-amount" borderless>
            <tbody>
              <tr>
                <td>
                  <span className=" d-flex table-content-text">
                    Delivery Charges
                  </span>
                  <p className="item-quantity">
                    Delivery charge is applicable if the total amount is less
                    than ₹ 120
                  </p>
                </td>
                <td>
                  <span className="cart-product-amount-price mt-4">₹ 0.0</span>
                </td>
              </tr>
            </tbody>
          </Table>
          <span className="cart-divider" />
          <Table className="mb-0 cart-prodict-sub-total-amount " borderless>
            <tbody>
              <tr>
                <td>
                  <span className=" d-flex table-content-text">
                    Total Amount
                  </span>
                </td>
                <td>
                  <span className="cart-product-amount-price mt-4">
                    To be decided
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
  function PrescriptionUploaded() {
    return (
      <>
        {PrescriptionImageData.length > 0 ? (
          <div className="d-flex mt-2 mb-4 ml-2 d-flex flex-column">
            <span className=" text-secondary">Prescription Uploaded</span>
            <div id="img_preview" className="mt-4">
              {PrescriptionImageData.map((imagePath, index) => (
                <>
                  {/* <ImCancelCircle
                    className="removeCircle"
                    onClick={() => removePrescription(index)}
                  /> */}
                  <img
                    src={imagePath?.image}
                    alt="homeo"
                    className="prescription-images mr-3"
                    key={index}
                  />
                </>
              ))}
            </div>
          </div>
        ) : null}
        {prescriptionNote.length > 0 ? (
          <>
            <div className="d-flex mt-2 mb-4">
              <span className="upload-text">Notes Added</span>
            </div>
            <div>
              {prescriptionNote.map((note, index) => (
                <div className="d-flex mt-2 md-2">
                  {console.log(note, "HELLONOTO")}
                  <p className="myindex">#{index + 1}</p>
                  <FormGroup style={{ minWidth: "50%" }}>
                    <span
                      className="checkout-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Notes
                    </span>
                    <Input
                      type="text"
                      name="prescriptionnote"
                      id="checkout-form-shiping-ph-number"
                      className="py-2 border-0 pl-0 mt-3 mynote"
                      onChange={(e) =>
                        updatepresctionNote(index, e.target.value, 1)
                      }
                      value={note.prescription_text}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup className="ml-3">
                    <span
                      className="checkout-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Quanity :
                    </span>
                    &nbsp;{note.quantity}
                  </FormGroup>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </>
    );
  }

  function FieldError({ error, touched }) {
    return error && touched ? (
      <div style={{ color: "#e65e52", marginBottom: "10px" }}>{error}</div>
    ) : null;
  }
  function AddressFregment() {
    const [ShowSelectAddressModal, setShowSelectAddressModal] = useState(false);
    const [
      ShowShippingSelectAddressModal,
      setShowShippingSelectAddressModal,
    ] = useState(false);

    const onContinueAddressModal = ({
      type,
      address,
      setFieldValue,
      values,
    }) => {
      const addressToFill = commands.cart.convertAddress({
        type,
        extaddress: address,
      });

      if (typeof addressToFill === "object") {
        setFieldValue({ ...values, ...addressToFill });
      }
      setShowSelectAddressModal(false);
      setShowShippingSelectAddressModal(false);
    };
    const [values, setValues] = useState(undefined);
    const [initialAddress, setInitialAddress] = useState(undefined);
    const validationSchema = validationSchemas.addressForm;
    const history = useHistory();

    useEffect(() => {
      if (values !== undefined) {
        addAddressToCart(values);
      }
    }, [values]);

    useEffect(() => {
      if (initialAddress === undefined) {
        setInitialAddress({
          saveBillingAddress: false,
          saveShippingAddress: false,

          bid: undefined,
          bname: "",
          bhouseNumber: "",
          baddressLine1: "",
          baddressLine2: "",
          bcity: "",
          bstate: "",
          bcountry: "",
          bpincode: "",
          bphone: "",

          isShippingAddressSame: true,

          sid: undefined,
          sname: "",
          shouseNumber: "",
          saddressLine1: "",
          saddressLine2: "",
          scity: "",
          sstate: "",
          scountry: "",
          spincode: "",
          sphone: "",
        });
      }
    }, []);

    async function addAddressToCart(values) {
      try {
        var billingAddress = {};
        var shippingAddress = {};
        var finalAddress = {};

        billingAddress = {
          name: values.bname,
          flat_no: values.bhouseNumber,
          address: values.baddressLine1,
          address_line_2: values.baddressLine2,
          city: values.bcity,
          state: values.bstate,
          country: values.bcountry,
          zip_code: values.bpincode,
          phone_number: values.bphone,
          is_default: false,
          save_address: values.saveBillingAddress,
        };

        shippingAddress = {
          name: values.sname,
          flat_no: values.shouseNumber,
          address: values.saddressLine1,
          address_line_2: values.saddressLine2,
          city: values.scity,
          state: values.sstate,
          country: values.scountry,
          zip_code: values.spincode,
          phone_number: values.sphone,
          is_default: false,
          save_address: values.saveShippingAddress,
        };

        if (values.isShippingAddressSame === true) {
          finalAddress = {
            billing_same_as_shipping: true,
            delivery_address_id: values.bid,
          };
          if (!values.bid) {
            finalAddress.address = billingAddress;
          }
        } else {
          finalAddress = {
            billing_same_as_shipping: false,
            delivery_address_id: values.sid,
            billing_address_id: values.bid,
          };

          if (!values.bid) {
            finalAddress.address = { billing_address: billingAddress };
          }

          if (!values.sid) {
            finalAddress.address = {
              ...finalAddress.address,
              ...shippingAddress,
            };
          }
        }

        const success = await commands.cart.addAddress({
          address: finalAddress,
        });
        if (success === true) {
          history.push("#payment");
        }
      } catch (error) {
        window.notify([
          {
            message:
              error.message || "Error occured while adding address to cart.",
          },
        ]);
      } finally {
        setValues(undefined);
      }
    }
    useEffect(() => {
      if (values !== undefined) {
        addAddressToCart(values);
      }
    }, [values]);

    useEffect(() => {
      if (initialAddress === undefined) {
        setInitialAddress({
          saveBillingAddress: false,
          saveShippingAddress: false,

          bid: undefined,
          bname: "",
          bhouseNumber: "",
          baddressLine1: "",
          baddressLine2: "",
          bcity: "",
          bstate: "",
          bcountry: "",
          bpincode: "",
          bphone: "",

          isShippingAddressSame: true,

          sid: undefined,
          sname: "",
          shouseNumber: "",
          saddressLine1: "",
          saddressLine2: "",
          scity: "",
          sstate: "",
          scountry: "",
          spincode: "",
          sphone: "",
        });
      }
    }, []);
    async function addAddressToCart(values) {
      try {
        var billingAddress = {};
        var shippingAddress = {};
        var finalAddress = {};

        billingAddress = {
          name: values.bname,
          flat_no: values.bhouseNumber,
          address: values.baddressLine1,
          address_line_2: values.baddressLine2,
          city: values.bcity,
          state: values.bstate,
          country: values.bcountry,
          zip_code: values.bpincode,
          phone_number: values.bphone,
          is_default: false,
          save_address: values.saveBillingAddress,
        };

        shippingAddress = {
          name: values.sname,
          flat_no: values.shouseNumber,
          address: values.saddressLine1,
          address_line_2: values.saddressLine2,
          city: values.scity,
          state: values.sstate,
          country: values.scountry,
          zip_code: values.spincode,
          phone_number: values.sphone,
          is_default: false,
          save_address: values.saveShippingAddress,
        };

        if (values.isShippingAddressSame === true) {
          finalAddress = {
            billing_same_as_shipping: true,
            delivery_address_id: values.bid,
          };
          if (!values.bid) {
            finalAddress.address = billingAddress;
          }
        } else {
          finalAddress = {
            billing_same_as_shipping: false,
            delivery_address_id: values.sid,
            billing_address_id: values.bid,
          };

          if (!values.bid) {
            finalAddress.address = { billing_address: billingAddress };
          }

          if (!values.sid) {
            finalAddress.address = {
              ...finalAddress.address,
              ...shippingAddress,
            };
          }
        }

        const success = await commands.cart.addAddress({
          address: finalAddress,
        });
        if (success === true) {
          history.push("#payment");
        }
      } catch (error) {
        window.notify([
          {
            message:
              error.message || "Error occured while adding address to cart.",
          },
        ]);
      } finally {
        setValues(undefined);
      }
    }
    async function myPrescription(values) {
      setLoader(true);
      if (PrescriptionImageData.length > 0) {
        let res = await commands.prescription.uploadPrescription({
          flow: 1,
          prescription_item: PrescriptionImageData,
          address: values,
          onSuccess: () => history.push("/prescription-uploaded"),
        });
      } else {
        let res = await commands.prescription.uploadPrescription({
          flow: 2,
          prescription_item: prescriptionNote,
          address: values,
          onSuccess: () => history.push("/prescription-uploaded"),
        });
      }
    }
    return (
      <Fragment>
        <div className="checkout-form-wrap">
          <Form>
            <Fragment>
              {initialAddress && (
                <Formik
                  initialValues={initialAddress}
                  onSubmit={(values) => myPrescription(values)}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    setFieldTouched,
                    touched,
                    isValid,
                    handleSubmit: originalSubmit,
                    setFieldValue,
                    setValues,
                    setErrors,
                    setTouched,
                    validateForm,
                  }) => {
                    const cacheState = useSelector((state) => state.cache);

                    useEffect(() => {
                      setValues(values);
                    }, [cacheState?.language]);

                    const handleSubmit = (e) => {
                      if (
                        values.bid > 0 &&
                        Object.keys(errors).find(
                          (item) => item.charAt(0) === "b"
                        )
                      ) {
                        setValues({ ...values, bid: undefined });
                      }

                      if (
                        values.sid > 0 &&
                        Object.keys(errors).find(
                          (item) => item.charAt(0) === "s"
                        )
                      ) {
                        setValues({ ...values, sid: undefined });
                      }
                      originalSubmit(e);
                    };

                    return (
                      <Fragment>
                        <AllModal
                          modalName="selectaddress"
                          type="billing"
                          isOpen={ShowSelectAddressModal}
                          toggle={() =>
                            setShowSelectAddressModal(!ShowSelectAddressModal)
                          }
                          onContinue={onContinueAddressModal}
                          setFieldValue={(values) => {
                            setErrors({});
                            setTouched({});
                            setValues(values);
                          }}
                          values={values}
                        />
                        <AllModal
                          modalName="selectaddress"
                          type="shipping"
                          isOpen={ShowShippingSelectAddressModal}
                          toggle={() =>
                            setShowShippingSelectAddressModal(
                              !ShowShippingSelectAddressModal
                            )
                          }
                          onContinue={onContinueAddressModal}
                          setFieldValue={(values) => {
                            setErrors({});
                            setTouched({});
                            setValues(values);
                          }}
                          values={values}
                        />

                        <div className="cart-pg-inner-wrap bg-white radius-10 cart-pg-mb-30">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h2 className="cart-checkout-tile mt-0">
                              Billing Address
                            </h2>
                            <Button
                              color="link cart-select-aar-btn"
                              onClick={() => setShowSelectAddressModal(true)}
                            >
                              Select Address
                            </Button>
                          </div>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Name
                                </span>
                                <Input
                                  type="text"
                                  name="bname"
                                  id="checkout-form-name"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bname")}
                                  value={values.bname}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bname}
                                  touched={touched.bname}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Flat / House / Apartment No.
                                </span>
                                <Input
                                  type="text"
                                  name="bhouseNumber"
                                  id="checkout-form-address"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bhouseNumber")}
                                  value={values.bhouseNumber}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bhouseNumber}
                                  touched={touched.bhouseNumber}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Address Line 1
                                </span>
                                <Input
                                  type="text"
                                  name="baddressLine1"
                                  id="checkout-form-address-2"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() =>
                                    setFieldTouched("baddressLine1")
                                  }
                                  value={values.baddressLine1}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.baddressLine1}
                                  touched={touched.baddressLine1}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Address Line 2
                                </span>
                                <Input
                                  type="text"
                                  name="baddressLine2"
                                  id="checkout-form-address-3"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() =>
                                    setFieldTouched("baddressLine2")
                                  }
                                  value={values.baddressLine2}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.baddressLine2}
                                  touched={touched.baddressLine2}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  City
                                </span>
                                <Input
                                  type="text"
                                  name="bcity"
                                  id="checkout-form-city"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bcity")}
                                  value={values.bcity}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bcity}
                                  touched={touched.bcity}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  State
                                </span>
                                <Input
                                  type="text"
                                  name="bstate"
                                  id="checkout-form-state"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bstate")}
                                  value={values.bstate}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bstate}
                                  touched={touched.bstate}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Country
                                </span>
                                <Input
                                  type="text"
                                  name="bcountry"
                                  id="checkout-form-country"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bcountry")}
                                  value={values.bcountry}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bcountry}
                                  touched={touched.bcountry}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Pin Code
                                </span>
                                <Input
                                  type="text"
                                  name="bpincode"
                                  id="checkout-form-pincode"
                                  className="py-2 border-0 pl-0"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bpincode")}
                                  value={values.bpincode}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bpincode}
                                  touched={touched.bpincode}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <span className="checkout-form-label">
                                  Phone Number
                                </span>
                                <Input
                                  type="number"
                                  name="bphone"
                                  id="checkout-form-ph-number"
                                  className="py-2 border-0 pl-0 yt-remove-arrow"
                                  onChange={handleChange}
                                  onBlur={() => setFieldTouched("bphone")}
                                  value={values.bphone}
                                  disabled={values.bid}
                                />
                                <FieldError
                                  error={errors.bphone}
                                  touched={touched.bphone}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <div className="checkout-checkbox d-flex flex-wrap my-3">
                                <FormGroup className="mr-5">
                                  <span className="yt-checkout-chekbox-label">
                                    My billing and shipping address are the same
                                  </span>
                                  <Input
                                    type="checkbox"
                                    id="checkout-billing-addr"
                                    name="isShippingAddressSame"
                                    checked={values.isShippingAddressSame}
                                    onChange={handleChange}
                                  />{" "}
                                  <Label
                                    className="yt-checkout-form-chk-box"
                                    check
                                  />
                                </FormGroup>
                              </div>
                            </Col>
                            {/* <Col md={12}>
                              <>
                                {PrescriptionImageData.length > 0 ? (
                                  <div className="d-flex mt-2 mb-4 ml-2 d-flex flex-column">
                                    <span className="my-upload-text">
                                      Your Prescription Order
                                    </span>
                                    <div id="img_preview" className="mt-4">
                                      {PrescriptionImageData.map(
                                        (imagePath, index) => (
                                          <>
                                            <img
                                              src={imagePath.image}
                                              alt="homeo"
                                              className="prescription-images mr-3"
                                              key={index}
                                            />
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                                {prescriptionNote.length > 0 ? (
                                  <>
                                    <div className="d-flex mt-2 mb-4">
                                      <span className="my-upload-text">
                                        Your Prescription Order
                                      </span>
                                    </div>
                                    <div>
                                      {prescriptionNote.map((note, index) => (
                                        <div className="d-flex mt-2 md-2">
                                          {console.log(note, "HELLONOTO")}
                                          <p className="myindex">
                                            #{index + 1}
                                          </p>
                                          <FormGroup
                                            style={{ minWidth: "50%" }}
                                          >
                                            <span
                                              className="checkout-form-label"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Notes
                                            </span>
                                            <Input
                                              type="text"
                                              name="prescriptionnote"
                                              id="checkout-form-shiping-ph-number"
                                              className="py-2 border-0 pl-0 mt-3 mynote"
                                              onChange={(e) =>
                                                updatepresctionNote(
                                                  index,
                                                  e.target.value,
                                                  1
                                                )
                                              }
                                              value={note.prescription_text}
                                              disabled={true}
                                            />
                                          </FormGroup>
                                          <FormGroup className="ml-3">
                                            <span
                                              className="checkout-form-label"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Quanity :
                                            </span>
                                            &nbsp;{note.quantity}
                                          </FormGroup>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : null}
                              </>
                            </Col> */}
                          </Row>
                          {!values.isShippingAddressSame && (
                            <Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <h2 className="cart-checkout-tile mt-0">
                                  Shipping Address
                                </h2>
                                <Button
                                  color="link cart-select-aar-btn"
                                  onClick={() =>
                                    setShowShippingSelectAddressModal(true)
                                  }
                                >
                                  Select Address
                                </Button>
                              </div>
                              <Row form>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Name
                                    </span>
                                    <Input
                                      type="text"
                                      name="sname"
                                      id="checkout-form-shiping-name"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("sname")}
                                      value={values.sname}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.sname}
                                      touched={touched.sname}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Flat / House / Apartment No.
                                    </span>
                                    <Input
                                      type="text"
                                      name="shouseNumber"
                                      id="checkout-form-shiping-address"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() =>
                                        setFieldTouched("shouseNumber")
                                      }
                                      value={values.shouseNumber}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.shouseNumber}
                                      touched={touched.shouseNumber}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Address Line 1
                                    </span>
                                    <Input
                                      type="text"
                                      name="saddressLine1"
                                      id="checkout-form-shiping-address-1"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() =>
                                        setFieldTouched("saddressLine1")
                                      }
                                      value={values.saddressLine1}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.saddressLine1}
                                      touched={touched.saddressLine1}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Address Line 2
                                    </span>
                                    <Input
                                      type="text"
                                      name="saddressLine2"
                                      id="checkout-form-shiping-address-2"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() =>
                                        setFieldTouched("saddressLine2")
                                      }
                                      value={values.saddressLine2}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.saddressLine2}
                                      touched={touched.saddressLine2}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      City
                                    </span>
                                    <Input
                                      type="text"
                                      name="scity"
                                      id="checkout-form-shiping-city"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("scity")}
                                      value={values.scity}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.scity}
                                      touched={touched.scity}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      State
                                    </span>
                                    <Input
                                      type="text"
                                      name="sstate"
                                      id="checkout-form-shiping-state"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("sstate")}
                                      value={values.sstate}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.sstate}
                                      touched={touched.sstate}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Country
                                    </span>
                                    <Input
                                      type="text"
                                      name="scountry"
                                      id="checkout-form-shiping-country"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("scountry")}
                                      value={values.scountry}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.scountry}
                                      touched={touched.scountry}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Pin Code
                                    </span>
                                    <Input
                                      type="text"
                                      name="spincode"
                                      id="checkout-form-shiping-pincode"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("spincode")}
                                      value={values.spincode}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.spincode}
                                      touched={touched.spincode}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <span className="checkout-form-label">
                                      Phone Number
                                    </span>
                                    <Input
                                      type="number"
                                      name="sphone"
                                      id="checkout-form-shiping-ph-number"
                                      className="py-2 border-0 pl-0"
                                      onChange={handleChange}
                                      onBlur={() => setFieldTouched("sphone")}
                                      value={values.sphone}
                                      disabled={values.sid}
                                    />
                                    <FieldError
                                      error={errors.sphone}
                                      touched={touched.sphone}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <div className="checkout-checkbox d-flex flex-wrap my-3" />
                                </Col>
                              </Row>
                            </Fragment>
                          )}
                        </div>
                        <div className="d-none">
                          <div
                            className="w3-gray"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {JSON.stringify(values)}
                          </div>
                          <br />
                          <div style={{ overflowWrap: "break-word" }}>
                            {JSON.stringify(errors)}
                          </div>
                        </div>
                        <div className="text-right">
                          <Ripple>
                            <Button
                              color="btn  cart-proceed-to-checkput py-3 px-5"
                              onClick={handleSubmit}
                            >
                              Confirm Order
                            </Button>
                          </Ripple>
                        </div>
                      </Fragment>
                    );
                  }}
                </Formik>
              )}
            </Fragment>
          </Form>
        </div>
      </Fragment>
    );
  }
  function FinalCheckout() {
    return (
      <>
        {PrescriptionImageData.length > 0 ? (
          <div className="d-flex mt-2 mb-4 ml-2 d-flex flex-column">
            <span className="my-upload-text">Your Prescription Order</span>
            <div id="img_preview" className="mt-4">
              {PrescriptionImageData.map((imagePath, index) => (
                <>
                  <img
                    src={imagePath?.image}
                    alt="homeo"
                    className="prescription-images mr-3"
                    key={index}
                  />
                </>
              ))}
            </div>
          </div>
        ) : null}
        {prescriptionNote.length > 0 ? (
          <>
            <div className="d-flex mt-2 mb-4">
              <span className="my-upload-text">Your Prescription Order</span>
            </div>
            <div>
              {prescriptionNote.map((note, index) => (
                <div className="d-flex mt-2 md-2">
                  {console.log(note, "HELLONOTO")}
                  <p className="myindex">#{index + 1}</p>
                  <FormGroup style={{ minWidth: "50%" }}>
                    <span
                      className="checkout-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Notes
                    </span>
                    <Input
                      type="text"
                      name="prescriptionnote"
                      id="checkout-form-shiping-ph-number"
                      className="py-2 border-0 pl-0 mt-3 mynote"
                      onChange={(e) =>
                        updatepresctionNote(index, e.target.value, 1)
                      }
                      value={note.prescription_text}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup className="ml-3">
                    <span
                      className="checkout-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Quanity :
                    </span>
                    &nbsp;{note.quantity}
                  </FormGroup>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </>
    );
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="pageroute cart-pg-breadcrumbs my-3">
              <Link to="/">
                <span
                  className="cart-pg-home w3-hover-opacity"
                  style={{ cursor: "default" }}
                >
                  Home
                </span>
              </Link>
              {" > "}
              <span className="cart-pg-current">Prescription</span>
            </div>
            <h1 className="prescription-title mt-5">Order Medicines</h1>
          </Col>
        </Row>
      </Container>
      <section>
        {myloader ? (
          <>
            <PageLoadingBlock
              message="Uploading Your Prescription"
              title=" Please Wait..."
            />
          </>
        ) : (
          <>
            <Container className="checkout-form-wrap">
              <Row className="yt-cm-row mb-5">
                {ShowLogin ? (
                  <>
                    <Col md={8} className="yt-cm-lt-col">
                      <LoginComponent />
                    </Col>
                    <Col md={4} className="yt-cm-lt-col">
                      <Row>
                        <Col md={12}>
                          <div className="radius-10 bg-white myBlock">
                            <PrescriptionUploaded />
                          </div>
                        </Col>
                        <Col md={12} className="mt-3">
                          <CheckoutPrescription />
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : (
                  <>
                    {ShowCheckout ? (
                      <>
                        <Col md={8} className=" upload-block">
                          <AddressFregment />
                        </Col>
                        <Col md={4} className="yt-cm-lt-col">
                          <Row>
                            <Col md={12}>
                              <div className="radius-10 bg-white myBlock">
                                <FinalCheckout />
                              </div>
                            </Col>
                            <Col md={12} className="mt-3">
                              <CheckoutPrescription />
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={6} className="yt-cm-lt-col">
                          <div>
                            <img
                              src={require("./images/1Group 4163.jpg")}
                              alt="homeo"
                              className="img-fluid "
                            />
                          </div>
                        </Col>
                        <Col md={6} className="yt-cm-rt-col">
                          <div>
                            <img
                              src={require("./images/Group 4164.png")}
                              alt="homeo"
                              className="img-fluid "
                            />
                          </div>
                        </Col>
                        <Col md={12} className="bg-white mt-4 upload-block">
                          <>
                            <Row>
                              <Col md={6}>
                                <div className="d-flex mt-4 mb-4">
                                  <img
                                    src={require("./images/prescription.png")}
                                    alt="homeo"
                                    className="prescription-file-image"
                                  />
                                  <span className="upload-text">
                                    Please upload images of valid prescription
                                    from your doctor.
                                  </span>
                                </div>
                                <Form>
                                  <FormGroup>
                                    <div className="mb-4">
                                      <img
                                        src={require("./images/upload.png")}
                                        alt="homeo"
                                        className={classnames({
                                          "prescription-file-upload w3-hover-shadow filter-blur-img":
                                            prescriptionNote.length > 0,
                                          "prescription-file-upload w3-hover-shadow":
                                            prescriptionNote.length === 0,
                                        })}
                                        onClick={
                                          prescriptionNote.length === 0
                                            ? fileUploader
                                            : null
                                        }
                                      />
                                      <Input
                                        type="file"
                                        id="prescription-uploader"
                                        name="prescription-uploader"
                                        accept="image/*"
                                        multiple
                                        style={{ display: "none" }}
                                      />
                                      <div className="card-sub-text mt-4">
                                        {" "}
                                        - Or -
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Form>
                              </Col>
                              <Col md={6}>
                                <div className="d-flex mt-4 mb-4">
                                  <img
                                    src={require("./images/prescription.png")}
                                    alt="homeo"
                                    className="prescription-file-image"
                                  />
                                  <span className="upload-text">
                                    Please Add Note of your medicines along with
                                    Quanity.
                                  </span>
                                </div>
                                <div>
                                  {prescriptionNote.map((note, index) => (
                                    <div className="d-flex mt-2 md-2">
                                      <p className="myindex">#{index + 1}</p>
                                      <FormGroup style={{ minWidth: "60%" }}>
                                        <span
                                          className="checkout-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          Add Note
                                        </span>
                                        <Input
                                          type="text"
                                          name="prescriptionnote"
                                          id="checkout-form-shiping-ph-number"
                                          className="py-2 border-0 pl-0 mt-3 mynote"
                                          onChange={(e) =>
                                            updatepresctionNote(
                                              index,
                                              e.target.value,
                                              1
                                            )
                                          }
                                          // onBlur={() => setFieldTouched("prescriptionnote")}
                                          value={note.prescription_text}
                                          // disabled={values.sid}
                                        />
                                        {/* <FieldError
                                      error={errors.sphone}
                                      touched={touched.sphone}
                                    /> */}
                                      </FormGroup>
                                      <FormGroup className="ml-3">
                                        <span
                                          className="checkout-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          Quanity
                                        </span>
                                        <PrescriptionQuantityField
                                          CartQuantityVal={note.quantity}
                                          setCartQuantityVal={(val) => {
                                            setQuantity(val);
                                            updatepresctionNote(index, val, 2);
                                            // commands.cart.updateItem({
                                            //   productId: product?.product?.id,
                                            //   variantId: product?.product_variant?.id,
                                            //   quantity: val,
                                            // });
                                          }}
                                          className="mt-3"
                                          maxQuantity={20}
                                        />
                                      </FormGroup>
                                      <IoIosClose
                                        className="trash-icon"
                                        onClick={() =>
                                          removePrescriptionNote(index)
                                        }
                                      />
                                    </div>
                                  ))}
                                  <Button
                                    className={classnames({
                                      "add-more-button mb-4 disabled":
                                        PrescriptionImageData.length > 0,
                                      "add-more-button mb-4":
                                        PrescriptionImageData.length === 0,
                                    })}
                                    onClick={
                                      PrescriptionImageData.length === 0
                                        ? appendPrescriptionNote
                                        : null
                                    }
                                  >
                                    <SiPluscodes />{" "}
                                    {prescriptionNote.length > 0
                                      ? "Add More"
                                      : "Add Note"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            {PrescriptionImageData.length > 0 ? (
                              <div className="d-flex mt-4 mb-4 ml-2 d-flex flex-column">
                                <span className=" text-secondary">
                                  Prescription attached by you
                                </span>
                                <div id="img_preview" className="mt-4">
                                  {PrescriptionImageData.map(
                                    (imagePath, index) => (
                                      <>
                                        <ImCancelCircle
                                          className="removeCircle"
                                          onClick={() =>
                                            removePrescription(index)
                                          }
                                        />
                                        <img
                                          src={imagePath?.image}
                                          alt="homeo"
                                          className="prescription-images mr-3"
                                          key={index}
                                        />
                                      </>
                                    )
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </>
                        </Col>
                        {PrescriptionImageData.length > 0 ||
                        (!_.some(_.map(prescriptionNote, "prescription_text"), [
                          "length",
                          0,
                        ]) &&
                          !_.some(_.map(prescriptionNote, "quantity"), [
                            "length",
                            0,
                          ]) &&
                          prescriptionNote.length > 0) ? (
                          <Col md={12}>
                            <Button
                              className="float-right prescription-proceed"
                              onClick={proceedToCheckout}
                            >
                              Proceed
                            </Button>
                          </Col>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </Row>
            </Container>
          </>
        )}
      </section>
    </>
  );
}
export default PrescriptionPageComponent;
