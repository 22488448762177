import * as yup from 'yup';
import lang from "../../language";


export default function getLoginValidation() {
    const loginlang = new lang("loginScreen");
    let emaillang = new lang("validationErrors");
    return yup.object().shape({
        userEmail: yup
        .string()
        .required(emaillang.get("emailRequired", "Email is required.")),
        password: yup
            .string()
            .required(loginlang.get("passwordRequiredError", "Password is required."))
    });
}
