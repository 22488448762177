import React, { useRef, useState, useEffect } from "react";
import service, { serviceTypes } from "../../services";
import "./css/index.scoped.css";
import {
  Footer,
  HeroBanner,
  Header,
  AppStoreBanner,
  ThreePromo,
  SingleOfferBanner,
  PrescriptionOrderDetailsPage,
  DoubleOfferBanner,
  ProductCard,
} from "../../components";
import commands from "../../commands";
import AuthState from "../../redux/states/auth";
import lang from "../../language";

function PrescriptionOrderDetailPage(props) {
  const orderdetaillang = new lang("homeScreen");
  const authState = AuthState.get();
  const user = authState.user;
  const [trackingDetails, setTrackingDetails] = useState();
  const [prescriptionData, setprescriptionData] = useState([]);
  const orderItem = props.location?.state;

  useEffect(() => {
    fetchOrder();
  }, []);

  async function fetchOrder() {
    if(typeof authState === "object"){
      let serviceType
      serviceType = serviceTypes.getSinglePrescription(user?.id,orderItem.orderId)
      service(serviceType)
      .then(({ response, error })=>{
        if(error){
          window.notify([{ message: error.message, type: "warning" }]);
        }else if(response){
          setprescriptionData(response?.data?.data)
        }
      })
      
    }else{
      throw new Error("Login data error, try logout and login again.");
    }
    
  }

  return (
    <div>
      <Header onProps={props} />
      <PrescriptionOrderDetailsPage
       prescriptionOrder = {prescriptionData}
      />
      <Footer />
    </div>
  );
}
export default PrescriptionOrderDetailPage;
