import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  AppStoreBanner,
  ProductCard,
  CartPageComponent,
  ThreePromo2,
  ThreeImageBanner,
  HeroBanner,
  CollectionCard
} from "../../components";
import "./css/index.scoped.css";
import CacheState from "../../redux/states/cache";
function CartPage(props) {
  const cacheState = CacheState.get();

  return (
    <div>
      <Header onProps={props} />
      {/* <HeroBanner />
      <CollectionCard
        collections={
          cacheState.homepage?.categories 
        }
       
      /> */}
      <CartPageComponent />

      {/*Three Promo 2*/}
      <ThreePromo2 />
      {/*Three Promo 2 End*/}
       
      <ThreeImageBanner />

      {/*Similar Products Product */}
      <ProductCard
        products={cacheState.homepage?.latest_products}
        name="New Products"
        onViewMore={() =>
          props.history.push("/shop?order_field=recommended&page=1&per_page=15")
        }
      />
      {/*Similar Products Product */}

      {/* App Store Banner */}
      <AppStoreBanner />
      {/* App Store Banner End*/}
      <Footer />
    </div>
  );
}
export default CartPage;
